import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReportInput, TotalSalesReport } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";
import { format } from "date-fns";

export default class TotalSalesReportStores {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.totalSalesRegistry.clear();
        this.loadTotalSalesReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "0",
  });

  totalSalesRegistry = new Map<Date, TotalSalesReport>();

  resetTotalSalesRegistry = () => {
    this.totalSalesRegistry.clear();
  };

  loadTotalSalesReport = async () => {
    this.loadingInitial = true;
    try {
      this.totalSalesRegistry.clear();
      const result = await agent.Reports.getTotalSalesReport(this.axiosParams);
      runInAction(() => {
        result.forEach((totalSalesReport) => {
          this.setTotalSalesReport(totalSalesReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTotalSalesReport = (totalSalesReport: TotalSalesReport) => {
    totalSalesReport.date = new Date(totalSalesReport.date!);
    this.totalSalesRegistry.set(totalSalesReport.date, totalSalesReport);
  };

  get getTotalSalesReport() {
    return Array.from(this.totalSalesRegistry.values()).sort(
      (a, b) => a.date!.getTime() - b.date!.getTime()
    );
  }

  get groupedTotalSalesReport() {
    return Object.entries(
      this.getTotalSalesReport.reduce((groups, totalSales) => {

        const date = format(totalSales.date!, "PP");
        groups[date] = groups[date]
          ? [...groups[date], totalSales]
          : [totalSales];
        return groups;
      }, {} as { [key: string]: TotalSalesReport[] })
    );
  }

  calculateTotalSales = (data: TotalSalesReport[]) => {
    const summary = data.reduce(
      (accumulator: TotalSalesReport, item: TotalSalesReport) => {
        accumulator.totalLabor += item.totalLabor;
        accumulator.totalParts += item.totalParts;
        accumulator.totalMisc += item.totalMisc;
        accumulator.totalTax += item.totalTax;
        accumulator.totalFinal += item.totalFinal;
        return accumulator;
      },
      {
        totalLabor: 0,
        totalParts: 0,
        totalMisc: 0,
        totalTax: 0,
        totalFinal: 0,
      }
    );
    return summary;
  };

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  setPredicate = (startDate?: Date, endDate?: Date, type?: ReportType) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
  };
}
